<template>
  <a-drawer class="three-pillars-edit" title="三支柱相关" @close="onClose" :width="720" :visible="threePillarsEditVisiable" :maskClosable="false">

    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <!-- <a-form-model-item label="提交时间" prop="submissionTime" v-bind="formItemLayout" >
        <a-date-picker format="YYYY-MM-DD" v-model="form.submissionTime" />
      </a-form-model-item>
      <a-form-model-item label="员工工号" prop="employeeNo" v-bind="formItemLayout" >
        <a-input v-model="form.employeeNo" />
      </a-form-model-item>
      <a-form-model-item label="员工姓名" prop="employeeName" v-bind="formItemLayout" >
        <a-input v-model="form.employeeName" />
      </a-form-model-item>
      <a-form-model-item label="主题" prop="subject" v-bind="formItemLayout" >
        <a-input v-model="form.subject" />
      </a-form-model-item> -->
      <a-form-model-item label="工厂部门" prop="deptId" v-bind="formItemLayout" >
        <!-- <a-input v-model="form.deptName" /> -->
        <a-tree-select
          v-model="form.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptTreeData"
          placeholder="选择部门"
          @change="handleDeptChange"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="受理部门" prop="acceptDeptId" v-bind="formItemLayout" >
        <!-- <a-input v-model="form.acceptDeptName" /> -->
        <a-tree-select
          v-model="form.acceptDeptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptTreeData"
          placeholder="选择部门"
          @change="handleAcceptDeptChange"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="改进方案" prop="improvementPlan" v-bind="formItemLayout" >
        <a-textarea placeholder="不接受原因" v-model="form.improvementPlan" :rows="3" />
      </a-form-model-item>
      <!-- <a-form-model-item label="问题现状原因描述" prop="problemDesc" v-bind="formItemLayout" >
        <a-input v-model="form.problemDesc" />
      </a-form-model-item> -->
      <a-form-model-item label="评估者" prop="evaluatorName" v-bind="formItemLayout" >
        <a-input v-model="form.evaluatorName" />
      </a-form-model-item>
      <a-form-model-item label="审核状态" prop="status" v-bind="formItemLayout" >
        <a-select v-model="form.status">
          <a-select-option value="Not Start 待处理">Not Start 待处理</a-select-option>
          <a-select-option value="Processing 进行中">Processing 进行中</a-select-option>
          <a-select-option value="Closed 结案">Closed 结案</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否接受" prop="acceptStatus" v-bind="formItemLayout" >
        <a-select v-model="form.acceptStatus">
          <a-select-option value="Yes">Yes</a-select-option>
          <a-select-option value="No">No</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="不接受原因" prop="rejectReason" v-bind="formItemLayout" >
        <a-textarea placeholder="不接受原因" v-model="form.rejectReason" :rows="3" />
      </a-form-model-item>
      <a-form-model-item label="改善行动" prop="improveAction" v-bind="formItemLayout" >
        <a-textarea placeholder="改善行动" v-model="form.improveAction" :rows="3" />
      </a-form-model-item>
      <a-form-model-item label="预计完成日期" prop="planCompleteDate" v-bind="formItemLayout" >
        <a-date-picker format="YYYY-MM-DD" v-model="form.planCompleteDate" />
      </a-form-model-item>
      <a-form-model-item label="实际完成日期" prop="actCompleteDate" v-bind="formItemLayout" >
        <a-date-picker format="YYYY-MM-DD" v-model="form.actCompleteDate" />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import RangeDate from "@/components/datetime/RangeDate"
import DeptInputTree from "../../system/dept/DeptInputTree";
import moment from "moment"

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 }
}

export default {
  name: "ThreePillarsEdit",
  components: {RangeDate, DeptInputTree},
  props: {
    threePillarsEditVisiable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deptTreeData: [],
      formItemLayout,
      form: {
        id: null,
        acceptDeptName: "",
        acceptStatus: "",
        rejectReason: "",
        improveAction: "",
        actCompleteDate: null,
        deptName: "",
        employeeName: "",
        employeeNo: "",
        evaluatorName: "",
        improvementPlan: "",
        planCompleteDate: null,
        problemDesc: "",
        status: "",
        subject: "",
        submissionTime: null,
        deptId: "", 
        acceptDeptId: ""
      },
      rules: {
        deptId: [
          { required: true, message: '工厂部门不能为空', trigger: 'change' }
        ],
        acceptDeptId: [
          { required: true, message: '受理部门不能为空', trigger: 'change' }
        ],
        rejectReason: [
          { required: true, message: '不接受原因不能为空', trigger: 'change' }
        ],
        status: [
          { required: true, message: '审核状态不能为空', trigger: 'change' }
        ],
        planCompleteDate: [
          { required: true, message: '预计完成日期不能为空', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
    this.$get('dept').then((r) => {
      this.deptTreeData = r.data.rows.children;
    })
  },
  methods: {
    onClose() {
      this.$refs.ruleForm.resetFields();
      this.$emit("close");
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let submissionTime = this.form.submissionTime?this.form.submissionTime.add(1, 'days').toDate():null;
          let planCompleteDate = this.form.planCompleteDate?this.form.planCompleteDate.add(1, 'days').toDate():null;
          let actCompleteDate = this.form.actCompleteDate?this.form.actCompleteDate.add(1, 'days').toDate():null;
          // console.log("typeof ==>", typeof this.form.submissionTime.toDate(), this.form.submissionTime.toDate());
          this.$put("three_pillars", {...this.form, submissionTime, planCompleteDate, actCompleteDate}).then(r => {
            console.log("修改三支柱相关", r.data);
            this.$emit("success");
          })
        } else {
          return false;
        }
      });
    },
    setFormFields({...threePillars}) {
      // console.log("threePillars", threePillars);
      delete threePillars.key;
      threePillars.submissionTime = threePillars.submissionTime?moment(threePillars.submissionTime):null;
      threePillars.planCompleteDate = threePillars.planCompleteDate?moment(threePillars.planCompleteDate):null;
      threePillars.actCompleteDate = threePillars.actCompleteDate?moment(threePillars.actCompleteDate):null;
      this.form = threePillars;
    },
    handleDeptChange(value, label) {
      console.log("deptId ==>", value, label[0]);
      this.form.deptId = +value;
      this.form.deptName = label[0];
    },
    handleAcceptDeptChange(value, label) {
      console.log("acceptDeptId ==>", value, label[0]);
      this.form.acceptDeptId = +value;
      this.form.acceptDeptName = label[0];
    },
  }
}
</script>