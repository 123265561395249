<template>
  <a-card :bordered="false" class="card-area">

    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="4">
            <a-form-item label="工号">
              <a-input v-model="queryParams.employeeNo" placeholder="输入工号" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="部门">
              <dept-input-tree @change="handleDeptChange" ref="deptTree"></dept-input-tree>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="受理部门">
              <dept-input-tree @change="handleAcceptDeptChange" ref="deptTree"></dept-input-tree>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="审核状态">
              <a-select v-model="queryParams.status">
                <a-select-option value="Not Start 待处理">Not Start 待处理</a-select-option>
                <a-select-option value="Processing 进行中">Processing 进行中</a-select-option>
                <a-select-option value="Closed 结案">Closed 结案</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="接收与否">
              <a-select v-model="queryParams.acceptStatus">
                <a-select-option value="Yes">Yes</a-select-option>
                <a-select-option value="No">No</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="10">
           <a-form-item label="提交时间">
             <range-date @change="handleDateChange" ref="createTime"></range-date>
           </a-form-item>
          </a-col>
          <a-col :span="3" :style="{ textAlign: 'center' }">
            <a-button type="primary" @click="search">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    
    
    <div class="operator">
      <a-button>删除</a-button>
      <a-dropdown v-hasPermission="['project:export']">
        <a-menu slot="overlay">
          <a-menu-item key="export-data" @click="exportExcel">导出Excel</a-menu-item>
          <a-menu-item type="primary" @click="downloadTemplate" >模板下载</a-menu-item>
        </a-menu>
        <a-button>更多操作 <a-icon type="down" /></a-button>
      </a-dropdown>
      <a-upload
        :fileList="fileList"
        :remove="handleRemove"
        :disabled="fileList.length === 1"
        :beforeUpload="beforeUpload">
        <a-button>
          <a-icon type="upload" /> 选择.xlsx文件
        </a-button>
        <a-button
          class="button-area"
          @click="handleUpload"
          :disabled="fileList.length === 0"
          :loading="uploading">
          {{uploading ? '导入中' : '导入Excel' }}
        </a-button>
      </a-upload>
    </div>
    <a-table ref="TableInfo" :pagination="pagination" @change="handleTableChange" bordered :loading="loading" :columns="columns" :data-source="dataSource" :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}">
      <template slot="operation" slot-scope="text, record">
        <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="editThreePillars(record)" title="修改"></a-icon>
      </template>
    </a-table>
    <ThreePillarsEdit ref="threePillarsEdit" @close="handleThreePillarsEditClose" @success="handleThreePillarsEditSuccess" :threePillarsEditVisiable="threePillarsEditVisiable" />
  </a-card>
</template>

<script>
import ThreePillarsEdit from "./ThreePillarsEdit"
import RangeDate from "@/components/datetime/RangeDate"
import DeptInputTree from "../../system/dept/DeptInputTree";

const columns = [
  {
    title: "提交时间",
    dataIndex: "submissionTime",
    ellipsis: true
  },
  {
    title: "员工工号",
    dataIndex: "employeeNo",
    ellipsis: true
  },
  {
    title: "员工姓名",
    dataIndex: "employeeName",
    ellipsis: true
  },
  {
    title: "工厂部门",
    dataIndex: "deptName",
    ellipsis: true
  },
  {
    title: "主题",
    dataIndex: "subject",
    ellipsis: true
  },
  {
    title: "受理部门",
    dataIndex: "acceptDeptName",
    ellipsis: true
  },
  {
    title: "改进方案",
    dataIndex: "improvementPlan",
    ellipsis: true
  },
  {
    title: "问题现状原因描述",
    dataIndex: "problemDesc",
    ellipsis: true
  },
  {
    title: "评估者",
    dataIndex: "evaluatorName",
    ellipsis: true
  },
  {
    title: "审核状态",
    dataIndex: "status",
    // scopedSlots: { customRender: 'status' },
    ellipsis: true
  },
  {
    title: "是否接受",
    dataIndex: "acceptStatus",
    ellipsis: true
  },
  {
    title: "不接受原因",
    dataIndex: "rejectReason",
    ellipsis: true
  },
  {
    title: "改善行动",
    dataIndex: "improveAction",
    ellipsis: true
  },
  {
    title: "预计完成日期",
    dataIndex: "planCompleteDate",
    ellipsis: true
  },
  {
    title: "实际完成日期",
    dataIndex: "actCompleteDate",
    ellipsis: true
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: 'operation' },
    fixed: "right",
  }
];

export default {
  components: {ThreePillarsEdit, RangeDate, DeptInputTree},
  data() {
    return {
      columns,
      fileList: [],
      importData: [],
      times: '',
      errors: [],
      uploading: false,
      dataSource: [],
      selectedRowKeys: [],
      queryParams: {
        createTimeFrom: "",
        createTimeTo: "",
        status: "",
        acceptStatus: "",
        deptName: "",
        acceptDeptName: ""
      },
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false,
      threePillarsEditVisiable: false
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    downloadTemplate () {
      this.$download('three_pillars/template', {}, 'three_pillars_template.xlsx');
    },
    handleRemove (file) {
      if (this.uploading) {
        this.$message.warning('文件导入中，请勿删除')
        return
      }
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload (file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleUpload () {
      const { fileList } = this;
      const formData = new FormData();
      formData.append('file', fileList[0]);
      this.uploading = true;
      this.$upload('three_pillars/import', formData).then((r) => {
        console.log("三支柱导入excel==>", r.data.data);
        let data = r.data.data
        if (data !=undefined && data.length) {
          this.fetch();
        }
        this.importData = data == undefined ? [] : data;
        this.errors = data.error == undefined ? [] : data.error;
        this.times = data.time / 1000;
        this.uploading = false;
        this.fileList = [];
        this.importResultVisible = true;
      }).catch((r) => {
        console.error(r);
        this.uploading = false;
        this.fileList = [];
      })
    },
    exportExcel () {
      let threePillarsIds = '';
      if (this.selectedRowKeys.length) {
        threePillarsIds = this.selectedRowKeys.join(',');
      }
      // console.log("this.selectedRowKeys==>", this.selectedRowKeys);
      this.$export(`three_pillars/excel?threePillarsIds=${threePillarsIds}`, {
        ...this.queryParams
      })
    },
    onSelectChange (selectedRowKeys) {
      // console.log("selectedRowKeys==>", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    editThreePillars(record) {
      this.threePillarsEditVisiable = true;
      this.$refs.threePillarsEdit.setFormFields(record);
    },
    handleThreePillarsEditClose() {
      this.threePillarsEditVisiable = false;
    },
    handleThreePillarsEditSuccess() {
      this.threePillarsEditVisiable = false;
      this.$message.success("修改三支柱相关成功！");
      this.fetch();
    },
    handleDateChange (value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0]
        this.queryParams.createTimeTo = value[1]
      }
    },
    handleDeptChange(value, label) {
      // console.log("dept label==>", label);
      this.queryParams.deptName = label || "";
    },
    handleAcceptDeptChange(value, label) {
      // console.log("acceptDept label==>", label);
      this.queryParams.acceptDeptName = label || "";
    },
    search () {
      this.fetch({
        ...this.queryParams
      })
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination
      this.fetch({
        ...this.queryParams
      })
    },
    fetch (params = {}) {
      this.loading = true
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      this.$get('three_pillars', {
        ...params
      }).then((r) => {
        // console.log("三支柱分页==>", r.data);
        const pagination = { ...this.pagination };
        pagination.total = r.data.total;
        this.loading = false;
        let rows = r.data.rows;
        if(rows) {
          for (const key in rows) {
            rows[key]['key'] = +rows[key]['id'];
          }
        }
        this.dataSource = rows;
        this.pagination = pagination;
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>